
import Button from '@/components/Button.vue'
import { showAlert } from '@/components/popup/popup'
import useRequest from '@/hooks/useRequest'
import { translate } from '@/i18n'
import { bindFA, FAAccountType } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

const IFSCPattern = /^[A-Za-z0-9]{4}0[A-Za-z0-9]{6}$/

export default defineComponent({
  name: 'BindBankAccount',
  components: { Button, DialogTemplate },
  props: {
    name: String,
  },
  setup (props, ctx) {
    const [commit, progress] = useRequest(bindFA)
    const account = shallowRef('')
    const ifsc = shallowRef('')
    // const submit = bindBank(next)
    return {
      account,
      ifsc,
      submit () {
        if (IFSCPattern.test(ifsc.value)) {
          commit({
            type: FAAccountType.BANK_OF_IN,
            bankAccount: account.value,
            ifsc: ifsc.value,
          }).then(() => {
            ctx.emit('confirm')
            ctx.emit('close')
          })
        } else {
          showAlert(translate('withdrawal_72'))
        }
      },
      progress,
    }
  },
})
